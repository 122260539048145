<template>
	<div class="mareaoperacionFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mareaoperacion.idareaopera')" :value="getIdareaoperaValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mareaoperacion.codareaopera')" :value="getCodareaoperaValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mareaoperacion.codpue')" :value="getCodpueValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mareaoperacion.codareaoperaedi')" :value="getCodareaoperaediValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mareaoperacion-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mareaoperacion'
		};
	},
	computed: {
		getIdareaoperaValue() {
			return this.model && this.model.idareaopera ? this.model.idareaopera : '-';
		},
		getCodareaoperaValue() {
			return this.model && this.model.codareaopera ? this.model.codareaopera : '-';
		},
		getCodpueValue() {
			return this.model && this.model.codpue ? this.model.codpue : '-';
		},
		getCodareaoperaediValue() {
			return this.model && this.model.codareaoperaedi ? this.model.codareaoperaedi : '-';
		},
	}
}
</script>
